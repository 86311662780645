/*---------------------------
	Fonts
----------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

// fonts
$base-font-size: 15;
$base-font: "Roboto", sans-serif;
$heading-font: "Roboto", sans-serif;

// color
$dark-gray: #212158;
$body-color: #4f555a;
$white: #fff;
$light: #8188a9;
$black: #000;
$cyan: #848892;

$theme-primary-color: #c21246;
$theme-primary-color-s2: #5dc4b8;
$body-bg-color: #fff;
$section-bg-color: #fef6f3;
$section-bg-color2: #e9fafa;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #e1e1e1;
$border-color-s2: #d8e0f1;
